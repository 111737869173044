import {Route, Routes} from "react-router";
import Test from "./pkgs";

function Dispatch() {
  return (
      <Routes>
          <Route path="/" element={<Test />} />
      </Routes>
  );
}

export default Dispatch;
